body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: .75em;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.calendar {
  font-family: 'Ubuntu-Medium' !important;
  font-size: 13px !important;
  border-style: none !important;

}

.calendar>div>button,
.calendar>div>div>button,
.calendar>div>div>div>div>button {
  font-family: 'Ubuntu-Medium' !important;
  font-size: 11px !important;
  border-style: none !important;
  cursor: pointer;
}

.calendar>div>div>div>div>div>button {
  background-color: #F1F3F4 !important;
  border-width: 2px !important;
  border-color: white !important;
  border-style: solid !important;
  color: #51585E !important;
  padding: 7px !important;
}

.calendar>div>div>div>div>div>button[disabled] {
  opacity: 0.3;
}

.calendar>div>div>div>div>.date_selected {
  background-color: #EAC67F !important;
}

.calendar>div>div>div>div>div>.react-calendar__tile--active {
  background-color: #51585E !important;
  color: white !important;
}

.calendar>div>div>div>div>div>.date_selected2 {
  background-color: #07A0CE !important;
  color: white !important;
}

.calendar>div>div>div>div>div>.date_unselected {
  background-color: #F1F3F4 !important;
  color: #51585E !important;
}

.reservaEGo {
  background-image: linear-gradient(#FF472D, #FF472D);
}

.reservaEGoHorizontal {
  background-image: linear-gradient(to right, #FF472D, #FF472D);
}

textarea,
select,
input,
button {
  outline: none;
}

.reservas>select {
  font-family: 'Ubuntu-Medium';
  font-size: 12px;
  text-align-last: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  text-indent: 1px;
  text-overflow: '';
}

select {
  height: 45px;
  border-color: #E5E5E5;
  background-color: transparent;
  border-width: 1px;
  border-radius: 3px !important;
  /* font-size: 12pt; */
  font-family: "Ubuntu-Regular" !important;
  color: #51585E;
}

input[type=date] {
  height: 43px;
  border-color: #E5E5E5;
  text-transform: uppercase;
  background-color: transparent;
  border-width: 1px;
  border-radius: 3px !important;
  border-style: solid;
  outline: none;
  font-size: 12px;
  font-family: "Ubuntu-Regular" !important;
  color: #51585E;
  padding-left: 10px;
  padding-right: 10px;
}

option {
  /* font-size: 12pt; */
  font-family: "Ubuntu-Regular" !important;
}

.block {
  box-shadow: 0 0px 0px 0 #51585E, 0 0px 24px 0 #51585E30;
}

.dragscroll::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}